/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"); */


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  overflow: auto;
}
html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: "Inter", sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}

.loaderdiv-outer {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.loaderbox {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20vh;
  height: 20vh;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-edit {
  background-color: #fff3cd !important;
  color: #856404;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}
.action-view {
  background-color: #2639ff45 !important;
  color: #3a50e9;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}
.action-delete {
  background-color: #f8d7da !important;
  color: #721c24;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}

.react-action-class button {
  height: auto !important;
  width: auto !important;
}

.react-action-class button.table-action {
  border-radius: 5px;
  height: 24px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  text-align: center;
  width: 24px !important;
}

.chargeButton {
  background: rgb(44, 129, 255);
  color: black;
  border: none;
}

.react-action-class button {
  background: transparent;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9375rem;
  height: 1.875rem;
  margin-right: 5px;
  width: 1.875rem;
}

.evHeader {
  background-color: "rgb(44 92 255)";
}

/* .thead-color {
  border-bottom: 3px solid black;
  padding: 10px;
  text-align: left;
} */
.table td,
.table th {
  padding: 5px 0.75rem;
  border-top: 1px solid #dee2e6;
  height: 55px;
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 13px;
}
.table tr:nth-child(even) {
  background: #f7f6f6;
}
.table thead th {
  vertical-align: middle;
  font-weight: 600;
  border-top: 0;
}
.btn-success {
  font-size: 14px;
}
.table-white-box {
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 10px;
}
.table-white-box .table {
  border: 0;
  margin-bottom: 0;
}
.form-control {
  height: calc(1.71em + 0.75rem + 2px);
}
.btn-secondary {
  background-color: #333;
  border-color: #333;
}
.pos-relative.sidebar-whole-list {
  background: #f1f9f1;
  border-radius: 5px;
  border: 1px solid #d8ebd8;
}
.sidebar-wrapper .sidebar-whole-list .child-list {
  margin-top: 3px !important;
  padding-top: 1px;
}

.previewImage {
  width: 60px;
  height: 60px;
  border: 1px solid rgb(221, 221, 221);
  padding: 3px;
  margin-top: 10px;
}

.imageView {
  height: 60px;
  width: 80px;
}
.btnsize {
  width: 77px;
}
.confirmBtn {
  width: 77px;
  height: 40px;
  padding: 8px;
}
.RedClass {
  color: Red;
  font-weight: bold;
}

.EmailShow {
  padding: 5px 0.75rem;
  height: 55px;
  vertical-align: middle;
  font-size: 13px;
  text-transform: lowercase !important;
}

.action-view {
  background-color: #d7e9f1 !important;
  color: #1066e9;
  padding: 7px;
  font-size: 35px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: block;
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #cccccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type="time"]:focus {
  border: 3px solid #555;
}

.thead-success {
  color: white;
  background-color: #30c464;
}

.viewCharger {
  height: 45px !important;
}

.time-placeholder {
  position: absolute;
  right: 29px;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(78%);
  color: #aaa;
}
.dashboard-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #25547b14;
  border-radius: 10px;
  position: relative;
  text-align: left;
  border: 1px solid #ddd;
}
.centered-datepicker {
  text-align: center;
}
.dashboard-datepicker {
  margin-bottom: 12px !important;
  border-radius: 12px !important;
  border-color: #00d60b !important;
  padding: 5px !important;
}
.card-title {
  margin-bottom: 0.75rem;
  display: flex;
  gap: 10px;
  align-items: center;
}

.table-responsive {
  overflow-x: unset !important;
}

.login-container {
  /* border: 2px solid red; */
  color: #e8e8e8;
  background-color: #222222;
  min-height: 100dvh;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.login-row {
  /* border: 2px solid blue; */
  /* background-color: #ffffff; */
  /* padding: 20px; */
  max-width: 450px;
  width: 100%;
  border-radius: 10px;
  position: relative;
}
.login-logo-data-box {
  /* text-align: center; */
  margin: 1.2rem 0;
  
}
.back-link {
  /* border: 2px solid #e8e8e8; */
  background-color:  #e8e8e8;
  border-radius: 50px;
  padding:4px 13px;
  margin-left:0;
  font-size: 19px;
  display:inline
}
.back-link *{
  color: black;
  text-decoration: none;
}
.back-link * :hover{
  color:black;
  text-decoration: underline;
}
.login-title {
  /* border: 2px solid yellow; */
  font-size: 16px;
  /* line-height: 150%; */
  /* letter-spacing: 0.5px; */
  /* text-align: center; */
  /* color: #000; */
}
.login-title h2{
  font-weight: 900;
}
.login-form-main .form-group {
  /* border: 2px solid blue; */
  margin-bottom: 1.5rem;
}

.login-form-main .form-group .field-title{
  display: block;
  /* border: 2px solid black; */
  ;
}
.login-form-main .form-control {
  border: 2px solid #ebf0ff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: calc(190% - 0.8px);
  padding: 12px 12px 12px 12px;
  height: auto;
  /* border: 2px solid green; */
  width: 100%;
}
.login-input-box {
  position: relative;
}
.login-input-box span{
  display: inline-block;
  margin-bottom: 0.8rem;
}

.forgot-link{
  /* font-size: 25px; */
  font-weight: bolder; 
}
.error-msg{
  color: red;
}
.login-btn {
  /* border: 10px solid red; */
  display: block;
  width: 100%;
  box-shadow: 0px 10px 30px 0px #40bfff3d;
  background-color: #000000;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid transparent;
  font-size: 17px;
  font-weight: 700;
  line-height: 190%;
  text-align: center;
  color: #ffffff;
  /* margin-top: 16px; */
  margin: 1.8rem 0;
}
.login-container footer{
  /* border: 2px solid black; */
  font-size: 15px;
}
.account-text {
  text-align: center;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin-top: 24px;
  color: #888888;
  font-weight: 400;
}
.account-text > span {
  cursor: pointer;
  font-weight: 700;
  color: #40bfff;
}
.login-input-box .input-group-append {
  position: absolute;
  top: 70%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 9;
}
.logout-btn {
  display: inline-block;
  padding: 8px 20px !important;
  font-size: 16px;
  color: white !important;
  background-color: #40bfff;
  border-radius: 10px;
  border: 1px solid transparent;
}
.nav-item {
  display: inline-flex;
  align-items: center;
}
